.quddropdown {
  background-color: rgb(240, 240, 240);
  color: black;
  padding: 10px;
}

.qudraganddrop {
  background-color: rgb(240, 240, 240);
  color: black;
  padding: 10px;
}

.counters {
  background-color: rgb(240, 240, 240);
  color: black;
  width: 95%;
  padding: 10px;
}

.qusinglechoice {
  background-color: rgb(240, 240, 240);
  color: black;
  padding: 10px;
}

.quslider {
  background-color: rgb(240, 240, 240);
  color: black;
  width: 95%;
  padding: 10px;
}

.quslider_box_vertical {
  height: 200px;
  width: auto !important;
  display: flex;
  align-items: flex-start;
}

.qumultichoice {
  background-color: rgb(240, 240, 240);
  color: black;
  padding: 10px;
}

.qusingleline {
  background-color: rgb(240, 240, 240);
  color: black;
  padding: 10px;
}

.qusingleline-stem {
  color: black;
  padding-bottom: 15px;
}

.qusingleline-value {
  color: black;
  resize: none;
  width: 100%;
  padding: 0px;
  outline: none;
}

.qumultiline {
  background-color: rgb(240, 240, 240);
  color: black;
  padding: 10px;
}

.qumultiline-stem {
  color: black;
  padding-bottom: 15px;
}

.qumultiline-required {
  outline: 3px solid red;
}

.qumultiline-value {
  color: black;
  resize: none;
  width: 100%;
  padding: 0px;
  outline: none;
}

.quwideexample {
  color: green;
}

.qusinglepick {
  margin: 10px;
}

.quslider-value {
  width: 25px;
  padding: 5px;
  height: 20px;
}

.quslider_box_vertical .quslider-value {
  margin-left: 10px;
}

.MuiTableCell-root {
  border-bottom: 0px !important;
}
